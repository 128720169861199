import React from 'react'
import {Link} from "react-router-dom";
import classNames from "classnames";

type DuoLinkProps = {
    children?: React.ReactNode,
    link?: string
}

const DuoLink = ({children, link}: DuoLinkProps) => {

    const backgroundClasses = classNames('order-1 bg-gray-400 rounded-lg shadow-xl w-5/12 px-6 py-4', 'bg-secondary bg-diamonds-pattern')

    let linkOrEmpty = link ? link : ""

    let external = /^https?:\/\//.test(linkOrEmpty);
    let linkComponent;

    if (external) {
        linkComponent = (
            <a className={backgroundClasses} href={linkOrEmpty}>
                {children}
            </a>
        )
    } else {
        linkComponent = (
            <Link className={backgroundClasses} to={linkOrEmpty}>
                {children}
            </Link>
        )
    }

    return (
        linkComponent
    )

}

export default DuoLink