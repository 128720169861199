import React from 'react'
import type {StrictModifiers} from '@popperjs/core';
import {createPopper} from '@popperjs/core';
import DropdownMenu from "./DropdownMenu";

type DropdownProps = {
    text: string
    children?: React.ReactNode
}

const Dropdown = ({text, children}: DropdownProps) => {

    const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState<boolean>(false);
    const btnDropdownRef = React.createRef<HTMLButtonElement>();
    const popoverDropdownRef = React.createRef<HTMLDivElement>();
    const openDropdownPopover = () => {
        if (btnDropdownRef.current && popoverDropdownRef.current) {
            createPopper<StrictModifiers>(btnDropdownRef.current, popoverDropdownRef.current, {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 8],
                        },
                    },
                ],
            });
            setDropdownPopoverShow(true);
        }
    };

    const focusClosePopper = (e: React.FocusEvent<HTMLDivElement>) => {
        if (!e.currentTarget.contains(e.relatedTarget as Node)) {
            setDropdownPopoverShow(false);
        }
    };

    return (
        <div className="flex flex-wrap">
            <div className="w-full sm:w-6/12 md:w-4/12 px-4"
                 onFocus={() => openDropdownPopover()}
                 onBlur={(e) => focusClosePopper(e)}>
                <div className="relative inline-flex align-middle w-full">
                    <button
                        className="text-primary-text font-bold uppercase text-sm px-2 py-1
                        hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150
                        border-2 border-primary-detail border-opacity-60 hover:border-opacity-100"
                        type="button"
                        ref={btnDropdownRef}
                    >
                        {text}
                    </button>
                </div>
                <DropdownMenu popoverDropdownRef={popoverDropdownRef} hidden={!dropdownPopoverShow}>
                    {children}
                </DropdownMenu>
            </div>
        </div>
    )

}

export default Dropdown