import React from 'react'
import Navbar from "../common/navbar/Navbar";

const Gdpr = () => {

    return (
        <div className="container mx-auto w-full h-screen">
            <Navbar/>
            <div className="py-20 my-2 container mx-auto">
                <h3 className="mb-3 font-bold text-xl text-secondary-detail">GDPR compliant testdata at scale</h3>
                <p className="text-primary-text">
                    NAV has multiple complex systems that contain personal and sensitive information. Each system
                    has been built over numerous years and runs on everything between COBOL and Kotlin.
                    <br/>
                    Each application has a different point of intergration. We designed a scaleable solution
                    to produce anonymous and representative data that can be used in test environments.
                </p>
                <br/>
                <img className="max-h-64 mx-4 my-1 float-left" src={process.env.PUBLIC_URL + "NAV_Gdpr.jpeg"}
                     alt="gdpr"/>
                <br/>
                <p className="text-primary-text">
                    We created an orchestrator to schedule invocation of the generation of data during periods of low
                    activity to avoid interupting active testing.
                    <br/>
                    The orchestrator calls an API in the integration applications which then requests data from a
                    shared API. This api starts a kubernetes container which loads a predefined ML model which
                    has been created using data from the specific application which we are generating data for.
                    The output from the ML API is in JSON and has to be translated to the different integration types
                    which the NAV applications support.
                    <br/>
                    <br/>
                    Each application have different integration points. The main component which was the first to be
                    integrated with is the Population registrar which contain the most personal data about people in
                    Norway. This has a plain text integration which is supplied by Skatte-etaten. This was replicated
                    and generated by the integration application for the People registrar.
                    <br/>
                    <br/>                    The same method was used for all the applications which recieved external data in order to
                    ensure the internal processing was done by the applications. The method to anonymize data directly
                    in
                    the database turned out to be insuficient due to external applications and their dependency on
                    recieving correct data.
                </p>
                <p className="text-primary-text">
                    This project was developed while I was working at NAV and cooperation with Visma. I was the
                    only in house worker on the project and acted in an unofficial tech lead role during the
                    implemenation
                    and the late development of the system.

                    A full description and implementation can be found at{' '}
                    <a className="hover:text-secondary-detail text-primary-detail"
                       href="https://www.visma.no/consulting/prosjekter/nav-syntetiske-data/">
                        Visma's webpages
                    </a>
                </p>
            </div>
        </div>
    )

}

export default Gdpr