import React, {ReactNode} from 'react'
import {TimelineContentProps} from "./TimelineContent";

type Props = {
    right?: boolean,
    children?: ReactNode
}

const TimelineSegment = ({right, children}: Props) => {

    const mappedChildren = React.Children.map(children, (child, i) => {
        if (!React.isValidElement<TimelineContentProps>(child)) {
            return child
        }
        return (
            <div>
                {React.cloneElement(child, {
                    right: right
                })}
            </div>
        )
    })

    return (
        <div className="container inline-flex flex-col">
            {mappedChildren}
        </div>
    )

}

export default TimelineSegment