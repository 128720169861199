import React from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Home from "../home/Home";
import StonkPage from "../stonks/StonkPage";
import Gdpr from "../gdpr/Gdpr";

const BaseRouter = () =>
    <Router>
        <Switch>
            <Route path="/" exact>
                <Home/>
            </Route>
            <Route path="/stonks">
                <StonkPage/>
            </Route>
            <Route path="/gdpr">
                <Gdpr/>
            </Route>
        </Switch>
    </Router>

export default BaseRouter