import './index.css'
import BaseRouter from "./router/BaseRouter";

function App() {
  return (
    <div className="bg-primary">
      <BaseRouter/>
    </div>
  );
}

export default App;
