import React from 'react'

type Props = {
    children?: React.ReactNode
}

const Timeline = ({children}: Props) => {

    return (
        <div>
            <div className="relative wrap overflow-hidden p-10 h-full border-t-2 border-primary-detail">
                <div className="border-2-2 absolute border-opacity-60 border-secondary-detail h-full border inset-x-1/2"/>
                {children}
            </div>
        </div>
    )

}

export default Timeline