import React from 'react'
import classNames from "classnames";
import DuoLink from "../../common/DuoLink";


export type TimelineContentProps = {
    right?: boolean,
    text: string,
    title: string,
    nodeText: string,
    bgColor?: string,
    imgPath?: string,
    link?: string
}

const TimelineContent = ({right, nodeText, title, text, link, imgPath}: TimelineContentProps) => {

    const classes = classNames('mb-8 flex justify-between items-center w-full xs:flew-row-reverse', {'sm:flex-row-reverse': !right})

    return (
        <div className={classes}>
            <div className="sm:order-1 sm:w-5/12"/>
            <div className="z-20 flex items-center order-1 bg-secondary shadow-xl px-1 h-8 rounded">
                <div className="mx-auto font-semibold md:text-lg sm:text-sm text-xs text-primary-text">{nodeText}</div>
            </div>
            <DuoLink link={link}>
                <h3 className="mb-3 font-bold text-secondary-detail md:text-xl sm:text-md text-xs">{title}</h3>
                <p className="text-sm leading-snug tracking-wide text-primary-text text-opacity-100">
                    {text}
                </p>
                {imgPath && <img className="my-5 object-scale-down max-h-24" src={process.env.PUBLIC_URL + imgPath}
                                 alt="Logo"/>}
            </DuoLink>
        </div>
    )

}

export default TimelineContent