import React, {HTMLProps, ReactNode} from 'react'

export type TabProps = {
    active?: boolean,
    text: string,
    index?: number,
    children?: ReactNode
} & HTMLProps<any>

const Tab = ({active, text, children, onClick}: TabProps) => {

    let classes = 'inline-block py-2 px-4 text-primary-text hover:text-blue-darker font-semibold'

    if (active) {
        classes = 'inline-block border-l border-t border-r rounded-t py-2 px-4 text-primary-text font-semibold border-primary-detail'
    }

    return (
        <>
            <div className={classes} onClick={onClick}>{text}</div>
        </>
    )

}

export default Tab