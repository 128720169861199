import React, {HTMLProps, useState} from "react";
import classNames from "classnames";
import _uniqueId from "lodash/uniqueId";

type BaseProps = {
    register?: any,
    name: string,
    label?: string
} & HTMLProps<any>

type InputProps = {} & BaseProps

type SubmitProps = {
    text: string,
    register?: any
} & HTMLProps<any>

type SelectProps = {
    options?: any[]
} & BaseProps

const sharedClasses = 'border-2 border-primary-detail text-primary-text bg-secondary border-opacity-50 px-2 mx-2 h-full'
const labelText = 'text-primary-text'

export function Input({register, name, label, ...rest}: InputProps) {
    rest.className = classNames(rest.className, sharedClasses)

    const [id] = useState(_uniqueId('input-'));

    return (
        <span>
            {label && <label htmlFor={id} className={labelText}>{label}</label>}
            <input id={id} {...register(name)} {...rest} />
        </span>
    );
}

export function Select({register, options, name, label, ...rest}: SelectProps) {

    rest.className = classNames(rest.className, sharedClasses)
    const [id] = useState(_uniqueId('select-'));

    return (
        <span>
            {label && <label className={labelText} htmlFor={id}>{label}</label>}
            <select {...register(name)} {...rest}>
            {
                options!.map(value => (
                    <option key={value} value={value}>
                        {value}
                    </option>
                ))
            }
            </select>
        </span>
    );
}

export const SubmitButton = ({register, text, ...rest}: SubmitProps) => {
    rest.className = classNames(rest.className, sharedClasses, 'bg-secondary-detail')
    return (
        <button {...rest} type="submit">{text}</button>
    )
}