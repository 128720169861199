import React from 'react'
import {Link} from 'react-router-dom'

type DropdownLinkProps = {
    text: string,
    link: string
}

const DropdownLink = ({text, link}: DropdownLinkProps) => {

    return (
        <Link
            to={link}
            className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-secondary hover:text-secondary-detail"
        >
            {text}
        </Link>
    )

}

export default DropdownLink