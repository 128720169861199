//const apiKey = 'UMCSCB28ECBSFB55';
const errorMessage = 'Error Message'
const noteMessage = 'Note'
const quoteMessage = 'Global Quote'

export type StonkData = {
    time: string
    date: string
    open: number
}


export type QuoteData = {
    symbol: string,
    open: number,
    high: number,
    low: number,
    price: number,
    volume: number,
    prevClose: number,
    change: number,
    changePercent: string

}


export function fetchStonks(quote: string, time: number, apiKey: string) {
    const url = `https://www.alphavantage.co/query?function=TIME_SERIES_INTRADAY&symbol=${quote}&interval=${time}min&apikey=${apiKey}`;
    const timeSeriesName = `Time Series (${time}min)`

    return async () => await fetch(url).then(res => res.json()).then(json => {
        if (json[errorMessage] !== undefined) {
            throw new Error(json[errorMessage])
        } else if (json[noteMessage] !== undefined) {
            throw new Error(json[noteMessage])
        }
        if (json[timeSeriesName]) {
            const data = json[timeSeriesName]
            return Object.keys(data).map((k: string) => {
                return {
                    date: k.split(' ')[0],
                    time: k,
                    open: Number.parseFloat(data[k]['1. open'])
                } as StonkData
            }).sort((a, b) => a.time.localeCompare(b.time))
        }
        throw new Error()
    });
}

export function fetchQuote(quote: string, apiKey: string) {
    const url = `https://www.alphavantage.co/query?function=GLOBAL_QUOTE&symbol=${quote}&min&apikey=${apiKey}`;
    return async () => await fetch(url).then(res => res.json()).then(json => {
        if (json[errorMessage] !== undefined) {
            throw new Error(json[errorMessage])
        } else if (json[noteMessage] !== undefined) {
            throw new Error(json[noteMessage])
        }
        if (json[quoteMessage]) {
            const data = json[quoteMessage]
            return {
                symbol: data["01. symbol"],
                open: data["02. open"],
                high: data["03. high"],
                low: data["04. low"],
                price: data["05. price"],
                volume: data["06. volume"],
                prevClose: data["08. previous close"],
                change: data["09. change"],
                changePercent: data["10. change percent"],

            } as QuoteData
        }
        throw new Error()
    });
}