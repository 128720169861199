import React, {ReactNode, RefObject} from 'react'
import classNames from "classnames";

type DropdownMenuProps = {
    hidden?: boolean,
    popoverDropdownRef: RefObject<HTMLDivElement>,
    children?: ReactNode
}

const DropdownMenu = ({hidden, popoverDropdownRef, children}: DropdownMenuProps) => {

    const classes = classNames('text-base z-50 float-left list-none text-left rounded shadow-lg mb-1',
        {
            'hidden': hidden,
            'block': !hidden
        },
        'text-primary-text divide-y divide-primary-detail')

    return (
        <div
            ref={popoverDropdownRef}
            className={classes}
            style={{minWidth: '12rem'}}
        >
            {children}
        </div>
    )

}

export default DropdownMenu