import React from 'react'
import Navbar from "../common/navbar/Navbar";
import Timeline from "./timeline/Timeline";
import TimelineSegment from "./timeline/TimelineSegment";
import PersonalIntro from "./PersonalIntro";
import TimelineContent from "./timeline/TimelineContent";

const Home = () => {

    return (
        <div className="container mx-auto w-full h-full">
            <Navbar/>
            <PersonalIntro/>
            <Timeline>
                <TimelineSegment>
                    <TimelineContent text="Consultant" title="Distribution Innovation" nodeText="Des 2019"
                                     link="https://www.di.no"/>
                    <TimelineContent text="Senior consultant" title="Dfind Consulting" nodeText="Nov 2019"
                                     imgPath="/logo-dfind-svart-bakgrunn.svg" link="https://www.dfindconsulting.no"/>
                </TimelineSegment>
                <TimelineSegment right>
                    <TimelineContent text="System devloper" title="Nav" nodeText="Oct 2018" imgPath="/nav-logo-red.svg"
                                     link="/gdpr"/>
                </TimelineSegment>
                <TimelineSegment>
                    <TimelineContent text="" title="Gradiuation!" nodeText="Aug 2018" imgPath="/uit-logo.svg"/>
                    <TimelineContent text="Teacher" title="Kodeklubben" nodeText="2017"
                                     imgPath="Kodeklubben.png" link="https://www.kidsakoder.no/kodeklubb/tromso/"/>
                    <TimelineContent text="Teacher" title="Parallel programming" nodeText="2016"
                                     imgPath="/uit-logo.svg" link="https://uit.no/startsida"/>
                    <TimelineContent text="Student" title="University of Tromsø" nodeText="2012"
                                     imgPath="/uit-logo.svg" link="https://uit.no/startsida"/>
                </TimelineSegment>
            </Timeline>
        </div>)

}

export default Home