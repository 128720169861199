import React, {useEffect} from 'react'
import LineChart from "./LineChart";
import {useQuery, useQueryClient} from "react-query";
import {fetchStonks} from "./api/api";
import Spinner from "../common/Spinner";

type StonkGraphProps = {
    quote: string,
    time: number,
    apiKey: string,
}

const StonkGraph = ({quote, time, apiKey}: StonkGraphProps) => {
    const {data, status} = useQuery(['stonk', quote.toLowerCase(), time],
        fetchStonks(quote, time, apiKey),
        {
            refetchInterval: 1000 * 60,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false
        }
    )
    const queryClient = useQueryClient()

    useEffect(() => {
        queryClient.invalidateQueries(['stonk', quote.toLowerCase()])
    }, [queryClient, quote, time])


    return (
        <>
            {status === 'loading' && <Spinner/>}
            {status === 'success' && data && <LineChart data={data}/>}
        </>
    )

}

export default StonkGraph