import React from 'react'

type Props = {
    title: string
}

const Title = ({title}: Props) => {

    return (
        <a className="text-2xl text-primary-text italic" href="/">
            {title}
        </a>
    )

}

export default Title