import React from 'react'
import Title from "../Title";
import Dropdown from "../Dropdown/Dropdown";
import DropdownLink from "../Dropdown/DropdownLink";

const Navbar = () => {

    return (
        <nav>
            <div
                className="container mx-auto px-6 py-2 flex justify-between items-center  border-b-2 border-primary-detail ">
                <Title title="Teige"/>
                <div className="">
                    <ul className="flex">
                        <li className="flex-1"><a
                            className="p-4 hover:text-secondary-detail text-primary-text align-middle"
                            href="/#">About</a>
                        </li>
                        <li className="flex-1">
                            <Dropdown text="Projects">
                                <DropdownLink text="GDPR Syntethic testdata" link="/gdpr"/>
                                <DropdownLink text="Stonks" link="/stonks"/>
                            </Dropdown>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )

}

export default Navbar