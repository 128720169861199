import React from 'react'

const PersonalIntro = () => {

    return (
        <div className="py-20 my-2 container mx-auto">
            <img className="rounded-full max-h-24 object-left-top float-left mx-4 my-1"
                 src={process.env.PUBLIC_URL + "megselv.jpeg"} alt="timmern"/>
            <h3 className="mb-3 font-bold text-xl text-secondary-detail">Tim Teige</h3>
            <p className="text-primary-text">
                I am a passionate system developer with aditional interests in DevOps and architecture. I also dabble in basic ai development.
            </p>
            <br/>
            <p className="text-primary-text">
                I have a masters degree in computer science from the university in Tromsø.
                I now reside in Oslo and I have been working as a senior consultant for Dfind Consulting since 2019.
            </p>

        </div>
    )

}

export default PersonalIntro