import React from 'react'
import {SubmitHandler, useForm} from "react-hook-form";

type FormProps = {
    children?: React.ReactNode,
    onSubmit: SubmitHandler<any>,
    defaultValues: any

}

const Form = ({children, onSubmit, defaultValues}: FormProps) => {
    const methods = useForm({defaultValues});
    const {handleSubmit} = methods;
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {React.Children.map(children, child => {
                if (React.isValidElement<HTMLFormElement>(child)) {
                    return child.props.name
                        ? React.createElement(child.type, {
                            ...{
                                ...child.props,
                                register: methods.register,
                                key: child.props.name
                            }
                        })
                        : child;
                }
            })}
        </form>
    )

}

export default Form