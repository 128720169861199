import React, {useState} from 'react'
import Navbar from "../common/navbar/Navbar";
import StonkGraph from "./StonkGraph";
import {QueryClient, QueryClientProvider} from "react-query";
import StonkSearchForm from "./StonkSearchForm";
import TabView from "../common/tabView/TabView";
import Tab from "../common/tabView/Tab";

export type StonkState = {
    quote: string,
    time: number,
    apiKey: string
}

const StonkPage = () => {

    const [stonkState, setStonkState] = useState<StonkState>({
        quote: 'GME',
        time: 5,
        apiKey: ""
    })

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [activeTab, setActiveTab] = useState<number>(1)

    const queryClient = new QueryClient();
    const onSubmit = ({quote, time, apiKey}: StonkState) => {
        setStonkState({
            quote: quote ? quote : stonkState.quote,
            time: time ? time : stonkState.time,
            apiKey: apiKey ? apiKey : stonkState.apiKey
        })
    }
    return (
        <QueryClientProvider client={queryClient}>
            <div className="container mx-auto w-full h-screen">
                <Navbar/>
                <StonkSearchForm onSubmit={onSubmit} initialValues={stonkState}/>
                <TabView>
                    <Tab text="Graph"/>
                    {/*<Tab text="Table"/>*/}
                </TabView>
                {stonkState.apiKey && <StonkGraph quote={stonkState.quote} time={stonkState.time} apiKey={stonkState.apiKey}/> }
            </div>
        </QueryClientProvider>
    )

}

export default StonkPage