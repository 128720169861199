import React, {HTMLProps, ReactNode, useState} from 'react'
import {TabProps} from './Tab';

type TabViewProps = {
    children?: ReactNode
} & HTMLProps<any>

const TabView = ({children, onClick}: TabViewProps) => {

    const [isActive, setIsActive] = useState(0)

    const mappedChildren = React.Children.map(children, (child, i) => {
        if (!React.isValidElement<TabProps>(child)) {
            return child
        }
        let listItemClasses = 'mr-1 bg-secondary'
        if (i === isActive) {
            listItemClasses = '-mb-px mr-1 bg-secondary'
        }

        return (
            <li className={listItemClasses}>
                {React.cloneElement(child, {
                    ...child.props,
                    active: i === isActive,
                    index: i,
                    onClick: () => {
                        setIsActive(i)
                    }
                })}
            </li>
        )
    })

    return (
        <>
            <ul className="list-reset flex border-b border-primary-detail mb-2">
                {mappedChildren}
            </ul>
        </>
    )

}

export default TabView