import React from 'react'
import {LineChart, Line, XAxis, YAxis, ResponsiveContainer, Tooltip} from 'recharts';

export type TimeSeries = {
    date: string;
    time: string;
    open: number;
}

type Props = {
    data: TimeSeries[]
}

const StonkLineChart = ({data}: Props) => {

    const numbers = data.map((ts) => ts.open);
    const dates = data.map((ts) => new Date(ts.time));
    const max = Math.max(...numbers)
    const min = Math.min(...numbers)
    const start = dates.reduce(function (a, b) { return a < b ? a : b; });
    const end = dates.reduce(function (a, b) { return a > b ? a : b; });

    const mappedData = data.map((d) =>
        {
            return {
                open: d.open,
                time: d.time.split(' ')[1].split(':').slice(0, 2).join(':')
            }
        }
    )

    return (
        <ResponsiveContainer width="100%" height="50%">
            <LineChart width={400} height={400} data={mappedData} margin={{top: 5, right: 20, bottom: 5, left: 0}}>
                <Tooltip contentStyle={{ backgroundColor: '#474B4F', borderColor: '#86C232' }}
                         labelStyle={{ color: '#D1E8E2' }}
                         itemStyle={{ color: '#D1E8E2' }} />
                <Line type="monotone" dataKey="open" stroke="#86C232" dot={false}/>
                <XAxis dataKey="time" domain={[start.getTime, end.getTime]} tick={{fill: '#D1E8E2'}}/>
                <YAxis domain={[Math.round(min - 10), Math.round(max + 10)]} tick={{fill: '#D1E8E2'}}/>
            </LineChart>
        </ResponsiveContainer>
    )
}

export default StonkLineChart;