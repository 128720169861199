import React from 'react'
import Form from "../common/form/Form";
import {StonkState} from "./StonkPage";
import {Input, Select, SubmitButton} from "../common/form/Input";

type StonkSearchFormProps = {
    onSubmit: any,
    initialValues: StonkState
}

const StonkSearchForm = ({onSubmit, initialValues}: StonkSearchFormProps) => {

    return (
        <div className="flex m-auto mt-4 mb-2 flex-row-reverse">
            <Form onSubmit={onSubmit} defaultValues={initialValues}>
                <Input name="quote" type="text" defaultValue="GME" label="Stock"/>
                <Input name="apiKey" type="text" defaultValue="" placeholder="alphavantage-apiKey" label="ApiKey"/>
                <Select name="time" defaultValue={5} options={[1, 5, 15, 30, 60]} label="Interval"/>
                <SubmitButton text="Search"/>
            </Form>
        </div>
    )

}

export default StonkSearchForm